<template>
  <div data-app>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="menu-icon flaticon-clock-2"></i
        ><span class="ml-3 text-color">Client Contact Summary</span>
      </h3>
      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="Apply">
          <i class="material-icons">add</i> <span>Create</span></a
        >
      </span>
    </div>
  </div>
</template>
<script>
import BillingReport from "../../components/reprt/billingReport.vue";
import { mapGetters } from "vuex";
export default {
  components: { BillingReport },

  data() {
    return {
      form: {},
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters(["ClientsReport"]),
  },
  mounted() {},
  methods: {
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    ExportBilling() {
      var filename = "Client_Contact_List";
      var data = [];
      var header = "";
      data.push(`Client Personal Info Report`);

      header = `"Last Name","First Name","Start Of Care","Payer","UCI","Address","DOB","Status",${this.getPrimaryContact()}`;
      data.push(header); 
      this.ClientsReport.forEach((x) => { 
        var row = `"${x.lastName}","${x.firstName}","${this.dobformat2(
          x.joinDate
        )}","${x.payer}","${x.uci}","${x.address}","${this.dobformat2(
          x.dob
        )}","${x.status}","${this.getPrimaryContactData(x.primaryData)}" `;
        data.push(row);
      });

      this.downloadCSVFile(data.join("\n"), filename);
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
    getPrimaryContact() {
      var count = 0;
      this.ClientsReport.forEach((element) => {
        if (element.primaryData.length > count) {
          count = element.primaryData.length;
        }
      });
      var obj3 = `Primary Contact, Email ,Phone ,`;
      for (var i = 1; i <= count; i++) {
        obj3 += `Primary Contact ${i}, Email ${i} ,Phone ${i} ,`;
      }
      return obj3 ;
    },
    getPrimaryContactData(obj) {
      var data = [];  
      obj.forEach((element) => {
        data.push(element.name)
        data.push(element.email)        
        data.push(element.phoneNumber) 
      });
      
      return data.toString().replaceAll(",",'","');
    },

    async Apply() {
      this.loader = true;
      await this.$store
        .dispatch("getClientReports")
        .then((response) => {
          this.isfetching = false;
          
          if (response.length > 0) {
            this.ExportBilling();
          } else {
            this.loader = false;
            Swal.fire({
              title: "",
              text: "No Record Found",
              icon: "error",
              confirmButtonClass: "btn btn-danger",
            });
          }
        })
        .catch((ex) => {
          this.isfetching = false;
          
        });
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.text-color {
  color: #2d79bd;
}
</style>


